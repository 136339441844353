import React from "react";
import { Helmet } from "react-helmet";

const HelmetBase = ({
  title,
  description,
}) => {
  const passSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: `${title}`,
    description: `${description}`,
  };
  // alert(showAdd);
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />        
        <script type="application/ld+json">{JSON.stringify(passSchema)}</script>
        
       
      </Helmet>
    </>
  );
};
export default HelmetBase;
