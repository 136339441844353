import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import HelmetBase from "./HelmetBase";

// const baseUrl = "https://clownfish-app-92sqf.ondigitalocean.app";
// const baseUrl = "http://localhost:5000";
const baseUrl = "https://gravity-backend-two.vercel.app";

const Blog = () => {
  const { slug } = useParams();

  const [loadFlag, setLoadFlag] = useState(true);
  const [value, setValue] = useState({
    metatitle: "",
    metadesc: "",
    title: "",
    subTitle: "",
    writtenBy: "",
    desc: "",
    img: "",
  });
  const [tag, setTag] = useState("");

  useEffect(() => {
    getData();
  }, [slug]);

  const getData = async () => {
    setLoadFlag(true);
    const resp = await fetch(`${baseUrl}/blog/getBlogs?slug=${slug}`);
    const data = await resp.json();
    console.log(data);

    // document.getElementById('sub-title').innerText = data.data[0].subTitle;
    // document.getElementById('title').innerText = data.data[0].title
    // document.getElementById('written-by').innerText = data.data[0].writtenBy;
    // document.getElementById('blog-desc-det').innerHTML = data.data[0].desc;
    // document.getElementById('img').src = data.data[0].img.url;

    setValue({
      metatitle: data.data[0].metatitle,
    metadesc: data.data[0].metadesc,
      title: data.data[0].title,
      subTitle: data.data[0].subTitle,
      writtenBy: data.data[0].writtenBy,
      desc: data.data[0].desc,
      img: data.data[0].img.url,
    });

    let tags = `Tags: `;
    for (let i of data.data[0].tags[0].split(",")) {
      tags += `<a href="https://www.gravityinfosolutions.com/tag.html">${i}</a>, `;
    }
    // document.getElementById('tags').innerHTML = tags.slice(0, -2);
    setTag(tags.slice(0, -2));
    setLoadFlag(false);
  };

  return (
    <>
    {/* <Helmet>
                            <title>{value?.metatitle}</title>
                            <meta name="description" content={value?.metadesc} />
                          </Helmet> */}
                          <HelmetBase
        title={`${value?.metatitle}`}
        description={value?.metadesc}
      />
      {loadFlag ? (
        "Loading ..."
      ) : (
        <>
        
          {/* Cursor Animation */}
          <div className="cursor1" />
          <div className="cursor2" />
          {/* Preloader */}
          {loadFlag && (
            <div className="preloader">
              <div className="loading">
                <div className="bar bar1" />
                <div className="bar bar2" />
                <div className="bar bar3" />
                <div className="bar bar4" />
                <div className="bar bar5" />
                <div className="bar bar6" />
                <div className="bar bar7" />
                <div className="bar bar8" />
              </div>
            </div>
          )}
          {/* Scroll Smoother */}
          <div className="has-smooth" id="has_smooth" />
          {/* Go Top Button */}
          {/* <button id="scroll_top" class="scroll-top"><i class="fa-solid fa-arrow-up"></i></button> */}
          {/* Icon Area Start */}
          <div>
            <a
              href="https://wa.me/919599314863"
              className="float"
              target="_blank"
            >
              <i className="fa fa-whatsapp" />
            </a>
            <a
              href="mailto:info@gravityinfosolutions.com"
              className="float2"
              target="_blank"
            >
              <i className="fa fa-envelope" />
            </a>
          </div>
          {/* Icon Area End */}
          {/* Header area start */}
          <header className="header__area-2">
            <div className="header__inner-2">
              <div className="header__logo-2">
                <a
                  className="ch-2"
                  href="https://www.gravityinfosolutions.com/index.html"
                >
                  <img
                    src="/assets/imgs/logo/gravity.png"
                    style={{ width: 60 }}
                    alt="Site Logo"
                  />
                  <p>Gravity Infosolutions</p>
                </a>
              </div>
              <div className="header__nav-2">
                <ul className="main-menu menu-anim">
                  <li className="has-megamenu">
                    <a href="https://www.gravityinfosolutions.com/index.html">
                      home
                    </a>
                  </li>
                  <li>
                    <a href="https://www.gravityinfosolutions.com/why-gravityinfosolutions.html">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="https://www.gravityinfosolutions.com/service.html">
                      services
                    </a>
                    <ul className="main-dropdown">
                      <li>
                        <a>Salesforce Services</a>
                        <ul className="sub-dropdown">
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/Sales-cloud-consulting.html">
                              Sales Cloud Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/Service-cloud-consulting.html">
                              Service Cloud Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/marketing-cloud.html">
                              Marketing Cloud Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/community-cloud.html">
                              Community Cloud Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/salesforce-NPSP.html">
                              Salesforce NPSP
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/Salesforce-HEDA.html">
                              Salesforce HEDA
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/commerce-cloud-services.html">
                              Commerce Cloud Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/mulesoft-services.html">
                              Mulesoft Consulting Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/velocity-services.html">
                              Vlocity Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/financialforce-services.html">
                              FinancialForce Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/tableau-services.html">
                              Tableau Services
                            </a>
                          </li>
                          <li>
                            <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/lightning-web.html">
                              Lightning Web Component
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/salesforce-managed-services.html">
                          Salesforce Managed Services
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/support.html">
                          Salesforce Support Services
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/aws.html">
                          AWS Consulting
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/dynamics-365-crm.html">
                          Dynamics 365 CRM
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/metaverse.html">
                          Metaverse Consulting{" "}
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/google-cloud.html">
                          Google Cloud Serivces
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/netsuite-services.html">
                          NetSuite Services
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/sap.html">
                          SAP Implementation
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/it-staff-augmented.html">
                          IT Staff Augmentation
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/gcp.html">
                          GCP Services
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/quality-testing.html">
                          QA Testing
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/site-core.html">
                          Sitecore
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/Salesforce-Consulting/bi-analytics.html">
                          Bi &amp; Analytics
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="https://www.gravityinfosolutions.com/blog.html">
                      Media
                    </a>
                  </li>
                  <li>
                    <a href="https://www.gravityinfosolutions.com/contact-Salesforce-Partner-Developer-Administrator-Consultant.html">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header__nav-icon-2">
                <button
                  className="menu-icon-2"
                  id="open_offcanvas"
                  onClick={() => {
                    document
                      .querySelector(".offcanvas__area")
                      .classList.toggle("nav_active");
                  }}
                >
                  <img
                    src="/assets/imgs/icon/menu-white.png"
                    style={{ marginRight: 40 }}
                    alt="Menubar Icon"
                  />
                </button>
                <img
                  src="/assets/imgs/logos/greatplace.png"
                  width="40px"
                  alt="Offcanvas Image"
                />
              </div>
            </div>
          </header>
          {/* Header area end */}
          {/* Offcanvas area start */}
          <div className="offcanvas__area">
            <div className="offcanvas__body">
              <div className="offcanvas__left">
                <div
                  className="offcanvas__logo"
                  style={{ textAlign: "center" }}
                >
                  <a href="https://www.gravityinfosolutions.com/index.html">
                    <img
                      src="/assets/imgs/logo/gravity.png"
                      alt="Offcanvas Logo"
                    />
                  </a>
                  <h4 style={{ color: "white" }}>Gravity Infosolutions</h4>
                </div>
                {/* <div class="offcanvas__social">
    <h3 class="social-title">Follow Us</h3>
    <ul>
      <li><a href="#">Dribbble</a></li>
      <li><a href="#">Behance</a></li>
      <li><a href="https://www.instagram.com/gravityinfosolutions/">Instagram</a></li>
      <li><a href="https://www.facebook.com/gravityinfosolutionsllp/">facebook</a></li>
      <li><a href="https://twitter.com/BeingGravity">Twitter</a></li>
      <li><a href="#">YouTube</a></li>
    </ul>
  </div> */}
                <div className="offcanvas__image">
                  <img
                    src="/assets/imgs/logos/greatplace.png"
                    alt="Offcanvas Image"
                  />
                </div>
                <div className="offcanvas__links">
                  <ul>
                    <li>
                      <a href="https://www.gravityinfosolutions.com/why-gravityinfosolutions.html">
                        About
                      </a>
                    </li>
                    <li>
                      <a href="https://www.gravityinfosolutions.com/contact-Salesforce-Partner-Developer-Administrator-Consultant.html">
                        contact
                      </a>
                    </li>
                    <li>
                      <a href="https://www.gravityinfosolutions.com/hiring.html">
                        Career
                      </a>
                    </li>
                    <li>
                      <a href="https://www.gravityinfosolutions.com/blog.html">
                        Media
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="offcanvas__mid">
                <div className="offcanvas__menu-wrapper mean-container">
                  <nav className="offcanvas__menu mean-nav">
                    <ul className="menu-anim">
                      <li>
                        <a href="https://www.gravityinfosolutions.com/index.html">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/why-gravityinfosolutions.html">
                          about
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/service.html">
                          Service
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/blog.html">
                          Media
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/locations.html">
                          Locations
                        </a>
                      </li>
                      <li>
                        <a href="https://www.gravityinfosolutions.com/contact-Salesforce-Partner-Developer-Administrator-Consultant.html">
                          contact
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="offcanvas__right">
                <div className="offcanvas__search">
                  <form action="#">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search keyword"
                    />
                    <button>
                      <i className="fa-solid fa-magnifying-glass" />
                    </button>
                  </form>
                </div>
                <div className="offcanvas__contact">
                  <h3>Get in touch</h3>
                  <ul>
                    <li>
                      <a href="mailto:info@gravityinfosolutions.com">
                        info@gravityinfosolutions.com
                      </a>
                    </li>
                    <li>
                      247/1 Block D, 2nd floor, Sector 63, Noida, UP 201301
                    </li>
                  </ul>
                </div>
                <img
                  src="/assets/imgs/shape/11.png"
                  alt="shape"
                  className="shape-1"
                />
                <img
                  src="/assets/imgs/shape/12.png"
                  alt="shape"
                  className="shape-2"
                />
              </div>
              <div className="offcanvas__close">
                <button
                  type="button"
                  id="close_offcanvas"
                  onClick={() => {
                    document
                      .querySelector(".offcanvas__area")
                      .classList.toggle("nav_active");
                  }}
                >
                  <i className="fa-solid fa-xmark" />
                </button>
              </div>
            </div>
          </div>
          {/* Offcanvas area end */}
          <div id="smooth-wrapper">
            <div id="smooth-content">
              <main>
                {/* Blog detail start */}
                <section className="blog__detail">
                  <div className="container g-0 line pt-70">
                    <span className="line-3" />
                    <div className="row">
                      <div className="col-xxl-8 col-xl-10 offset-xxl-2 offset-xl-1">
                        <div className="blog__detail-top">
                          <br />
                          <br />
                          {/* <h2 class="blog__detail-date animation__word_come">Design, Marketing <span>25 Jan 2019</span></h2> */}
                          {/* <Helmet>
                            <title>{value?.metatitle}</title>
                            <meta name="description" content={value?.metadesc} />
                          </Helmet> */}
                          <br/> 
                          <br/>
                          <h3
                            id="title"
                            className="blog__detail-title animation__word_come"
                          >
                            {value?.title}
                          </h3>
                          <h2
                            id="sub-title"
                            className="blog__detail-date animation__word_come"
                          >
                            {value?.subTitle}
                          </h2>
                          <div className="blog__detail-metalist">
                            <div className="blog__detail-meta">
                              <p>
                                Writen by{" "}
                                <span id="written-by">{value?.writtenBy}</span>
                              </p>
                            </div>
                            {/* <div class="blog__detail-meta">
                <p>View <span>3 min read</span></p>
              </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12">
                        <div className="blog__detail-thumb">
                          {/* <img src="/assets/imgs/blog/detail/1.jpg" alt="Blog Thumbnail" data-speed="0.5"> */}
                          <img
                            id="img"
                            src={value?.img}
                            alt="Blog Thumbnail"
                            data-speed="0.5"
                          />
                        </div>
                      </div>
                      <div className="col-xxl-8 col-xl-10 offset-xxl-2 offset-xl-1">
                        <div className="blog__detail-tags">
                          {/* <p id="tags" class="sub-title-anim">tags: <a href="tag.html">design</a>, <a href="tag.html">figma</a>,
              <a href="tag.html">update</a>
            </p> */}
                          <p
                            id="tags"
                            className="sub-title-anim"
                            dangerouslySetInnerHTML={{ __html: tag }}
                          />
                        </div>
                        <div
                          className="blog__detail-content"
                          id="blog-desc-det"
                          dangerouslySetInnerHTML={{ __html: value.desc }}
                        >
                          {/*  <p>We love to bring designs to life as a developer, and I aim to do this using whatever front end
              tools are necessary. My preferred tools are more modern javascript libraries like React.js but I
              like to use whatever is best for the websites needs. There are several reasons why a business would
              consider a rebrand and it doesn’t necessarily mean the business has been unsuccessful. </p>
            <p>But in order that you may see whence all this born error of those who accuse pleasure and praise
              pain, I will open the whole matter, and explain the very things which were said by that discoverer
              of truth and, as it were, the architect of a happy life.</p>
            <img src="/assets/imgs/blog/detail/2.jpg" alt="Blog Image">
            <h2>JavaScript</h2>
            <p>We love to bring designs to life as a developer, and I aim to do this using whatever front end
              tools are necessary. My preferred tools are more modern javascript libraries like React.js but I
              like to use whatever is best for the websites needs. There are several reasons why a business would
              consider a rebrand and it doesn’t necessarily mean the business has been unsuccessful.</p>
            <h2>Fremework</h2>
            <p>Always ready to push the boundaries, especially when it comes to our own platform, Our analytical
              eye to create a site that was visually engaging and also optimised for maximum performance. It also
              perfectly reflects the journey to help it tell a story to increase its understanding and drive
              action. To create a site that was visually engaging for maximum performance.</p>
            <ul>
              <li>Brand Development</li>
              <li>UX/UI Design</li>
              <li>Front-end Development</li>
              <li>Copywriting</li>
              <li>Shopify Development</li>
            </ul>
            <h2>Visual Studio</h2>
            <p>Just like other pseudo-elements and pseudo-class selectors, :not() can be chained with other
              pseudo-classes and pseudo-elements. For example, the following will add a “New!” word to list items
              that do not have a .old class name, using the ::after pseudo-element:</p>
            <img src="/assets/imgs/blog/detail/3.jpg" alt="Code">*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* Blog detail end */}
                {/* Related blog start */}
                {/* <section class="blog__related blog__animation">
    <div class="container g-0 line pt-70 pb-70">
      <span class="line-3"></span>
      <div class="row">
        <div class="col-xxl-12">
          <div class="sec-title-wrapper">
            <h2 class="sec-title title-anim">Related Aricles</h2>
          </div>
        </div>
      </div>

      <div class="row reset-grid">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
          <article class="blog__item">
            <div class="blog__img-wrapper">
              <a href="blog-details.html">
                <div class="img-box">
                  <img class="image-box__item" src="/assets/imgs/blog/1.jpg" alt="Blog Thumbnail">
                  <img class="image-box__item" src="/assets/imgs/blog/1.jpg" alt="BLog Thumbnail">
                </div>
              </a>
            </div>
            <h4 class="blog__meta sub-title-anim"><a href="category.html">UI Design</a> . 02 May 2019</h4>
            <h5><a href="blog-details.html" class="blog__title sub-title-anim">Ways of lying to yourself about
                your new
                relationship.</a></h5>
            <a href="blog-details.html" class="blog__btn">Read More <span><i
                  class="fa-solid fa-arrow-right"></i></span></a>
          </article>
        </div>

        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
          <article class="blog__item" data-speed="auto">
            <div class="blog__img-wrapper">
              <a href="blog-details.html">
                <div class="img-box">
                  <img class="image-box__item" src="/assets/imgs/blog/2.jpg" alt="Blog Thumbnail">
                  <img class="image-box__item" src="/assets/imgs/blog/2.jpg" alt="BLog Thumbnail">
                </div>
              </a>
            </div>
            <h4 class="blog__meta sub-title-anim"><a href="category.html">UI Design</a> . 02 May 2019</h4>
            <h5><a href="blog-details.html" class="blog__title sub-title-anim">How to manage a talented and
                successful de sign
                team</a></h5>
            <a href="blog-details.html" class="blog__btn">Read More <span><i
                  class="fa-solid fa-arrow-right"></i></span></a>
          </article>
        </div>

        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
          <article class="blog__item" data-speed="auto">
            <div class="blog__img-wrapper">
              <a href="blog-details.html">
                <div class="img-box">
                  <img class="image-box__item" src="/assets/imgs/blog/3.jpg" alt="Blog Thumbnail">
                  <img class="image-box__item" src="/assets/imgs/blog/3.jpg" alt="BLog Thumbnail">
                </div>
              </a>
            </div>
            <h4 class="blog__meta sub-title-anim"><a href="category.html">UI Design</a> . 02 May 2019</h4>
            <h5><a href="blog-details.html" class="blog__title sub-title-anim">How to bring fold to your startup
                company with
                Gravity Infosolutions</a></h5>
            <a href="blog-details.html" class="blog__btn">Read More <span><i
                  class="fa-solid fa-arrow-right"></i></span></a>
          </article>
        </div>
      </div>
    </div>
  </section> */}
                {/* Related blog end */}
                {/* CTA area start */}

                <br />
                <br />
                <br />
                <br />
                <section className="cta__area">
                  <div className="container line">
                    <div className="line-3" />
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="cta__content">
                          <p className="cta__sub-title">Ideas can't wait</p>
                          <h2 className="cta__title title-anim">
                            Let's connect &amp; discuss further
                          </h2>
                          <div className="btn_wrapper">
                            <a
                              href="https://www.gravityinfosolutions.com/contact-Salesforce-Partner-Developer-Administrator-Consultant.html"
                              className="wc-btn-primary btn-item btn-hover"
                            >
                              <span />
                              Contact Us{" "}
                              <i className="fa-solid fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* CTA area end */}
              </main>
              {/* Footer area start */}
              <footer className="footer__area-3">
                <div className="footer__top-3">
                  <div className="footer__top-wrapper-3">
                    <div className="footer__logo-3 pt-120">
                      <div className="ch-2 ch-21">
                        <img
                          src="/assets/imgs/logo/gravity.png"
                          alt="Footer Logo"
                        />
                        <p>Gravity Infosolutions</p>
                      </div>
                      <p>
                        Experience the limitless power of Gravity Infosolutions
                        visionary Digital Transformation Services, propelling
                        businesses to new heights, transcending boundaries, and
                        shaping an unimaginable future.
                      </p>
                    </div>
                    <div className="footer__social-3">
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/gravityinfosolutionsllp/">
                            facebook
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/BeingGravity">Twitter</a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/gravityinfosolutions/">
                            Linkedin
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/gravityinfosolutions/">
                            Instagram
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/channel/UCZq7qGDEU4-iC1GFLRksojA">
                            Youtube
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="footer__contact-3">
                      <a
                        className="end"
                        href="https://www.gravityinfosolutions.com/contact-Salesforce-Partner-Developer-Administrator-Consultant.html"
                      >
                        Let’s connect
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer__btm-3">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4">
                        <div className="footer__copyright-3">
                          <p>
                            Gravity Infosolutions © 2023 | All rights reserved
                          </p>
                        </div>
                      </div>
                      <div className="col-xxl-8 col-xl-8 col-lg-8">
                        <div className="footer__nav-2">
                          <ul className="footer-menu-2 menu-anim">
                            <li>
                              <a href="https://www.gravityinfosolutions.com/industries.html">
                                Industries
                              </a>
                            </li>
                            <li>
                              <a href="https://www.gravityinfosolutions.com/locations.html">
                                Locations
                              </a>
                            </li>
                            <li>
                              <a href="https://www.gravityinfosolutions.com/testimonial.html">
                                Testimonials
                              </a>
                            </li>
                            <li>
                              <a href="https://www.gravityinfosolutions.com/contact-Salesforce-Partner-Developer-Administrator-Consultant.html">
                                contact
                              </a>
                            </li>
                            <li>
                              <a href="https://www.gravityinfosolutions.com/hiring.html">
                                Career
                              </a>
                            </li>
                            <li>
                              <a href="https://www.gravityinfosolutions.com/faq.html">
                                FAQs
                              </a>
                            </li>
                            <li>
                              <a href="https://www.gravityinfosolutions.com/privacy.html">
                                Privacy
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
              {/* Footer area end */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Blog;
